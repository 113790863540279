module Database

open Shared
open Router

let projects: Projects list = [
 {  Id = 2
    Name = "Bidflow"
    Subtitle = Localization.t "project.bidflow.subtitle"
    Applications = (Localization.t "project.web")
    LogoSrc = "./logos/effecta.svg"
    Description = [
        Localization.t "project.bidflow.description.1"
        Localization.t "project.bidflow.description.2"
        Localization.t "project.bidflow.description.3"

    ]
    Href = Router.toPath Router.Bidflow
    Mockups = [
        "./mockups/bidflow/bidflow1.png"
        "./mockups/bidflow/bidflow2.png"
        "./mockups/bidflow/bidflow3.png"
        "./mockups/bidflow/bidflow4.png"
    ]
    Technologies = [
        "./logos/latex.png"
        "./logos/fsharp.png"
        "./logos/dotnet.png"
        "./logos/gitlab.png"
        "./logos/react.png"
        "./logos/signalr.png"
        "./logos/jelastic.svg"
        "./logos/bulma.png"
        "./logos/eventstore.png"
        "./logos/mongo.png"
        "./logos/imageboss.svg"
        "./logos/safe.png"
    ] };
 {  Id = 1
    Name = "SBH"
    Subtitle = Localization.t "project.sbh.subtitle"
    Applications = ((Localization.t "project.web") + " | " + (Localization.t "project.mobile"))
    LogoSrc = "./logos/sbh.svg"
    Description = [
        Localization.t "project.sbh.description.1"
        Localization.t "project.sbh.description.2"
        Localization.t "project.sbh.description.3"
        Localization.t "project.sbh.description.4"
    ]
    Href = Router.toPath Router.SBH
    Mockups = [
        "./mockups/sbh/sbh1.png"
        "./mockups/sbh/sbh2.png"
        "./mockups/sbh/sbh3.png"
        "./mockups/sbh/sbh4.png"
        "./mockups/sbh/sbh5.png"
    ]
    Technologies = [
        "./logos/fsharp.png"
        "./logos/aws.png"
        "./logos/dotnet.png"
        "./logos/react.png"
        "./logos/signalr.png"
        "./logos/flutter.png"
        "./logos/firebase.png"
        "./logos/dart.png"
        "./logos/agora.svg"
        "./logos/googlemaps.svg"
        "./logos/gitlab.png"
        "./logos/fastlane.png"
    ]};
 {  Id = 3
    Name = "Endurance"
    Subtitle = Localization.t "project.endurance.subtitle"
    Applications = ((Localization.t "project.web") + " | " + (Localization.t "project.mobile"))
    LogoSrc = "./logos/endurance.svg"
    Description = [
        Localization.t "project.endurance.description.1"
        Localization.t "project.endurance.description.2"
    ]
    Href = Router.toPath Router.Endurance
    Mockups = [
        "./mockups/endurance/endurance1.png"
        "./mockups/endurance/endurance2.png"
        "./mockups/endurance/endurance3.png"
        "./mockups/endurance/endurance4.png"
    ]
    Technologies = [
        "./logos/flutter.png"
        "./logos/fsharp.png"
        "./logos/dotnet.png"
        "./logos/gitlab.png"
        "./logos/react.png"
        "./logos/signalr.png"
        "./logos/jelastic.svg"
        "./logos/bulma.png"
        "./logos/eventstore.png"
        "./logos/mongo.png"
        "./logos/safe.png"
        "./logos/fastlane.png"
    ]};
 {  Id = 4
    Name = "Direktpress"
    Subtitle = Localization.t "project.direktpress.subtitle"
    Applications = (Localization.t "project.web")
    LogoSrc = "./logos/pdf-dp.png"
    Description = [
        Localization.t "project.direktpress.description.1"
        Localization.t "project.direktpress.description.2"
    ]
    Href = Router.toPath Router.Direktpress
    Mockups = [
        "./mockups/direktpress/direktpress1.png"
        "./mockups/direktpress/direktpress2.png"
        "./mockups/direktpress/direktpress3.png"
    ]
    Technologies = [
        "./logos/react.png"
        "./logos/fsharp.png"
        "./logos/dotnet.png"
        "./logos/mysql.png"
        "./logos/gitlab.png"
        "./logos/docker.png"
        "./logos/entityframework.png"
        "./logos/Imagemagick.png"
    ]};
 {  Id = 5
    Name = "Pixie"
    Subtitle = Localization.t "project.pixie.subtitle"
    Applications = (Localization.t "project.web")
    LogoSrc = "./logos/pixie.svg"
    Description = [
        Localization.t "project.pixie.description.1"
        Localization.t "project.pixie.description.2"
        Localization.t "project.pixie.description.3"
    ]
    Href = Router.toPath Router.Pixie
    Mockups = [
        "./mockups/pixie/pixie1.png"
        "./mockups/pixie/pixie2.png"
        "./mockups/pixie/pixie3.png"]
    Technologies = [
        "./logos/dotnet.png"
        "./logos/kubernetes.png"
        "./logos/fsharp.png"
        "./logos/gitlab.png"
        "./logos/Sketch.png"
        "./logos/zeplin.png"
        "./logos/react.png"
        "./logos/safe.png"]};
 {  Id = 6
    Name = "Bärgarn"
    Subtitle = Localization.t "project.bärgarn.subtitle"
    Applications = ((Localization.t "project.web") + " | " + (Localization.t "project.mobile"))
    LogoSrc = "./logos/bargarn.svg"
    Description = [
        Localization.t "project.bärgarn.description.1"
        Localization.t "project.bärgarn.description.2"
        Localization.t "project.bärgarn.description.3"
    ]
    Href = Router.toPath Router.Bargarn
    Mockups = [
        "./mockups/bargarn/bargarn1.png"
        "./mockups/bargarn/bargarn2.png"
        "./mockups/bargarn/bargarn3.png"
        "./mockups/bargarn/bargarn4.png"
    ]
    Technologies = [
        "./logos/swagger.png"
        "./logos/bilvision.png"
        "./logos/Meteor.png"
        "./logos/expo.png"
        "./logos/googlemaps.svg"
        "./logos/react.png"
        "./logos/mongo.png"
        "./logos/gitlab.png"
        "./logos/azure.png"
        "./logos/Sketch.png"
        "./logos/zeplin.png"
        "./logos/fortnox.png"
    ]};
 {  Id = 7
    Name = "Touchpoint"
    Subtitle = Localization.t "project.touchpoint.subtitle"
    Applications  =  ((Localization.t "project.web") + " | " + (Localization.t "project.mobile"))
    LogoSrc = "./logos/touchpoint.svg"
    Description = [
        Localization.t "project.touchpoint.description.1"
        Localization.t "project.touchpoint.description.2"
    ]
    Href = Router.toPath Router.Touchpoint
    Mockups = [
        "./mockups/touchpoint/touchpoint1.png"
        "./mockups/touchpoint/touchpoint2.png"
        "./mockups/touchpoint/touchpoint3.png"
    ]
    Technologies = [
        "./logos/fsharp.png"
        "./logos/csharp.svg"
        "./logos/react.png"
        "./logos/dotnet.png"
        "./logos/mysql.png"
        "./logos/safe.png"
        "./logos/bulma.png"
        "./logos/zendesk.png"
    ]};
 {  Id = 8
    Name = "Shelfinity"
    Subtitle = Localization.t "project.shelfinity.subtitle"
    Applications = (Localization.t "project.web")
    LogoSrc = "./logos/shelfinity.svg"
    Description = [
        Localization.t "project.shelfinity.description.1"
        Localization.t "project.shelfinity.description.2"
    ]
    Href = Router.toPath Router.Shelfinity
    Mockups = [
        "./mockups/shelfinity/shel1.png"
        "./mockups/shelfinity/shel2.png"
        "./mockups/shelfinity/shel3.png"
        "./mockups/shelfinity/shel4.png"
        "./mockups/shelfinity/shel5.png"
    ]
    Technologies = [
        "./logos/dotnet.png"
        "./logos/IDserver.png"
        "./logos/sqlserver.png"
        "./logos/angular.svg"
        "./logos/angularmaterial.png"
        "./logos/googleanalitics.png"
        "./logos/Sketch.png"
        "./logos/zeplin.png"
        "./logos/hotjar.png"
        "./logos/stripe.png"
        "./logos/entityframework.png"
        "./logos/azure.png"
    ]};
]

let testimonials: Client list = [
    {Name = "Per Jakobsson"
     Company = "CEO at Pixie Data AB, Sweden"
     Text = "We have been working with Functor since 2019. Having Functor by our side not only provides great expertise in IT but also a sense of security. Functor takes complete responsibility and does not deliver anything they cannot stand behind. This applies to everything, such as the quality of their deliverables, planning, feedback, and documentation. We hope to continue working with Functor in the future."
     Image = "./img/avatar5.png"};
    {Name = "Linus Axelsson"
     Company = "CTO at SMS Heroes AB, Sweden"
     Text = "Functor has been with us from an early stage of creating our new platform, helping us overcome our obstacles. Not only great execution within design, development and testing - but also challenged our requirements to make our product even more powerful."
     Image = "./img/avatar1.png"};
    {Name = "Håkan Bergman"
     Company = "CTO at Datadelen, Sweden"
     Text = "Functor is taking your idea into reality. On time, on budget with perfect CI/CD operations that will make sure your product not only come to life but will also stay alive. We use Functor for web, native apps and support/maintenance.
From idea, strategy, design, development to delivery. You can't get any better, flexible, professional and passionate partner to work with."
     Image = "./img/avatar2.jpeg"};
    {Name = "Carin Lundén"
     Company = "CEO at Touchpoint Research AB, Sweden"
     Text = "Functor is a great IT-partner. As a highly professional company we expect high quality performance from our partners and Functor are definitely living up to our expectations. Their skills within IT-development are broad and they work with a clear project structure and dedication. Together with Functor Touchpoint Research can not only be better but excellent."
     Image = "./img/avatar3.jpeg"};
    {Name = "Pelle Lundgren"
     Company = "Co-founder at Shelfinity, Sweden"
     Text = "Very happy to have the honor to be working with functor.team. Technical savvy, outstanding communication and deep knowledge about what it means to deliver in an agile way in a startup environment. Recommend full hearty and looking forward to continuing working with."
     Image = "./img/avatar4.png"}
]