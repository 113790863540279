module LocalStorage

open Browser.WebStorage
open Fable.Core.JsInterop
open Fable.Core

type Lang =
    | Swedish
    | English

module Navigator =
    [<Emit("window.navigator.language")>]
    let language: string = jsNative

let currentLanguage () =
    match Browser.WebStorage.localStorage.getItem "lng" with
    | "en" -> "en"
    | "se" -> "se"
    | _ ->
        match Navigator.language with
        | "sv-SE" -> "se"
        | _ -> "en"
let currentLang () =
    match Browser.WebStorage.localStorage.getItem "lng" with
    | "en" -> Lang.English
    | "se" -> Lang.Swedish
    | _ ->
        match Navigator.language with
        | "en-EN" -> Lang.English
        | "sv-SE" -> Lang.Swedish
        | _ -> Lang.English

let saveLanguage (lng:Lang) =
    if  lng = Lang.Swedish then  localStorage.setItem("lng", "se" )
    else localStorage.setItem ("lng", "en")