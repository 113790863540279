module Components.Project

open Feliz
open Slider
open Feliz.UseDeferred
open Localization

[<ReactComponent>]
let View (id:int) =
    let loadData = async {
        do! Async.Sleep 1000
        let project =
                Database.projects
                |> List.tryFind(fun x -> x.Id = id)
                |> Option.defaultValue Database.projects.Head
        let prevProject =
            let prevId =
                if id = 1 then Database.projects.Length
                else id-1
            Database.projects
                |> List.tryFind(fun x -> x.Id = prevId)
                |> Option.defaultValue Database.projects.Head
        let nextProject =
            let nextId =
                if id = Database.projects.Length then 1
                else id+1
            Database.projects
                |> List.tryFind(fun x -> x.Id = nextId)
                |> Option.defaultValue Database.projects.Head
        return ( project, prevProject, nextProject )
    }

    let data = React.useDeferred(loadData, [| id|])
    match data with
    | Deferred.HasNotStartedYet
    | Deferred.InProgress ->
        Html.div [
            prop.style [style.display.flex;
                        style.width (length.vw 100)
                        style.height (length.vh 100)
                        style.justifyContent.center
                        style.alignItems.center]
            prop.children [
                Html.span [prop.className "loader"]
            ]
        ]
    | Deferred.Failed error -> Html.div error.Message
    | Deferred.Resolved (project, prevProject, nextProject) ->
        Html.div [
            Browser.Dom.window.scrollTo(0,0)
            let x = 5
            Html.div [
                prop.className "projectWrap"
                prop.children [
                    Html.div [
                        prop.className "project"
                        prop.children [
                            Html.a [
                                    prop.className "project__logo"
                                    prop.style [style.backgroundImageUrl "./img/symbol.png"]
                                    prop.href (Router.toPath Router.Home)]
                            Html.h1 [
                                prop.className "project__title title-first title-first_white"
                                prop.text project.Name
                            ]
                            Html.div [prop.className "project__line"]
                            Html.p [
                                prop.className "project__text paragraph"
                                prop.text project.Subtitle
                            ]
                        ]
                    ]
                    Html.div[
                        prop.style [style.padding (length.rem 4)]
                        prop.children [
                            SwitchLanguage.Render()
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "mockups"
                prop.children [
                    Slick.slider [
                        slider.className "mockups__images"
                        slider.dots false
                        slider.centerMode false
                        slider.arrows true
                        slider.variableWidth false
                        slider.autoplay true
                        slider.autoplaySpeed 4000
                        slider.children [
                            for link in project.Mockups do
                                Html.img [
                                    prop.src link
                                ]
                        ]
                    ]
                    Html.div [
                        prop.className "mockups__text paragraph paragraph_white"
                        prop.children [
                            Html.div [
                                prop.children [
                                    for text in project.Description do
                                        Html.p [
                                            prop.className "paragraph paragraph__white"
                                            prop.style[style.paddingBottom 10]
                                            prop.text text
                                        ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "mockups__line"
            ]
            Html.div [
                prop.className "stack"
                prop.children [
                    for tech in project.Technologies do
                        Html.img [
                            prop.src tech
                            prop.className "stack__logo"
                        ]
                ]
            ]
            Html.div [
                prop.className "nextPrev"
                prop.children [
                    Html.a [
                        prop.className "nextPrev__linkPrev"
                        prop.href prevProject.Href
                        prop.children [
                            Html.i [
                                prop.className "fa fa-angle-double-left nextPrev__icon"
                            ]
                            Html.p [
                                prop.className "nextPrev__text uppercaseThin"
                                prop.text (Localization.t "project.previous")
                            ]
                        ]
                    ]


                    Html.a [
                        prop.className "nextPrev__linkNext"
                        prop.href nextProject.Href
                        prop.children [
                            Html.p [
                                prop.className "nextPrev__text nextPrev__text__next uppercaseThin"
                                prop.text (Localization.t "project.next")
                            ]
                            Html.a[
                                prop.className "fa fa-angle-double-right nextPrev__icon"
                            ]
                        ]
                    ]


                    Html.div [
                        prop.className "nextPrev__line"
                    ]
                ]
            ]
            Footer.view
        ]