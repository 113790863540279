module SwitchLanguage

open Feliz
open LocalStorage
open Fable

[<ReactComponent>]
let Render() =
    let lng = currentLang ()
    let activeLng, setActiveLng = React.useState(lng)
    let switchLanguage  (newLng: Lang) =
        setActiveLng(newLng)
        LocalStorage.saveLanguage newLng
        Browser.Dom.document.location.reload ()
    Html.div [
        prop.className "lng"
        prop.children [
            Html.a [
                prop.className  [if activeLng = Lang.English then "lng__activ" else "lng__nonActive"]
                prop.text "EN"
                if activeLng = Lang.Swedish then prop.onClick(fun _ -> switchLanguage Lang.English)
            ]
            Html.span "/"
            Html.a [
                prop.className [if activeLng = Lang.Swedish then "lng__activ "else "lng__nonActive"]
                prop.text "SE"
                if activeLng = Lang.English then prop.onClick(fun _ -> switchLanguage Lang.Swedish)
            ]
        ]
    ]