module Home

open Shared
open Slider
open Feliz
open Localization


[<ReactComponent>]
let Render () =
    Html.div [
        prop.className "wrapper"
        prop.children [
            Html.div [
                prop.className "home"
                prop.children [
                    SwitchLanguage.Render()
                    Html.div [
                        prop.className "item item-1"
                        prop.children [
                            Html.div [prop.className "item-1__logo"
                                      prop.style [style.backgroundImageUrl "./img/symbol.png"]]
                            Html.h1 [
                                prop.className "item-1__title title-first title-first_white"
                                prop.text "Functor"
                            ]
                            Html.div [prop.className "item-1__line"]
                            Html.p [
                                prop.className "item-1__text title-third"
                                prop.text (Localization.t "main.subtitle")
                                // prop.text "We are a team of IT specialists with experience in software development for business. We understand that our customers need more than just software, but rather a solution to their business challenges, so our service is driven by this principle. We believe in agile and lean approaches to deliver measurable value through small steps gradually moving towards big goals."
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "item item-2"
                        prop.onClick (fun _ -> Browser.Dom.document.getElementById("help").scrollIntoView())
                        prop.children [
                            Html.div [
                                prop.className "item__subtitle"
                                prop.text (Localization.t "main.our")
                            ]
                            Html.div [
                                prop.className "item-2__title"
                                prop.children [
                                    Html.h1 [
                                        prop.className "title-first title-first__white"
                                        prop.text (Localization.t "main.services")
                                    ]
                                    Html.div [
                                        prop.className "fas fa-angle-double-down item-3__icon"
                                    ]
                                ]
                            ]
                            Html.img [
                                prop.src "./logos/Union.png"
                                prop.className "item-2__logo"
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "item item-3"
                        prop.children [
                            Html.div [
                                prop.className "item-3__subtitle"
                                prop.text  (Localization.t "main.our")
                            ]
                            Html.div [
                                prop.className "item-3__title"
                                prop.onClick (fun _ -> Browser.Dom.document.getElementById("clients").scrollIntoView())
                                prop.children [
                                    Html.h1 [
                                        prop.className "title-first"
                                        prop.text  (Localization.t "main.clients")
                                    ]
                                    Html.div [
                                        prop.style [style.color "#1A759F"]
                                        prop.className "fas fa-angle-double-down item-3__icon"
                                    ]
                                ]
                            ]
                            Html.img [
                                prop.src "./logos/Intersect.png"
                                prop.className "item-3__logo"
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "help"
                prop.id "help"
                prop.children [
                    Html.div [
                        prop.className "help__item"
                        prop.children [
                            Html.h3 [
                                prop.className "title-third title-third_black"
                                prop.style [ style.paddingBottom 10 ]
                                prop.text (Localization.t "help.business.title")
                            ]
                            Html.span [
                                prop.className "title-forth title-forth_blue"
                                prop.text (Localization.t "help.business.createAndLaunch")
                            ]
                            Html.span [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.business.solution")
                            ]
                            Html.span [
                                prop.className "title-forth title-forth_blue"
                                prop.text (Localization.t "help.business.maintainAndImprove")
                            ]
                            Html.span [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.business.existing")
                            ]
                            Html.span [
                                prop.className "title-forth title-forth_blue"
                                prop.text (Localization.t "help.business.digitalTransformation")
                            ]
                            Html.p [prop.style[style.margin 25]]
                            Html.p [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.business.weOffer")
                            ]
                            Html.p [prop.style[style.margin 10]]
                            Html.ul [
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text (Localization.t "help.business.web")
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text (Localization.t "help.business.prototyping")
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text (Localization.t "help.business.automation")
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text (Localization.t "help.business.cloud")
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text (Localization.t "help.business.ongoing")
                                ]
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "help__item"
                        prop.children [
                            Html.h3 [
                                prop.className "title-third title-third_black"
                                prop.style [ style.paddingBottom 10 ]
                                prop.text (Localization.t "help.team.title")
                            ]
                            Html.span [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.team.establish")
                            ]
                            Html.span [
                                prop.className "title-forth title-forth_blue"
                                prop.text (Localization.t "help.team.ongoingCooperation")
                            ]
                            Html.span [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.team.withOurExperienced")
                            ]
                            Html.span [
                                prop.className "title-forth title-forth_blue"
                                prop.text (Localization.t "help.team.developers")
                            ]
                            Html.span [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.team.incorporate")
                            ]
                            Html.p [prop.style[style.margin 25]]
                            Html.p [
                                prop.className "paragraph paragraph_black"
                                prop.text (Localization.t "help.team.technologies")
                            ]
                            Html.p [prop.style[style.margin 10]]
                            Html.ul [
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text ".NET Core, F#/C#"
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text "React"
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text "Fable, Feliz, Saturn/Giraffe, Dapper, Equinox"
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text "Event sourcing, SQRS"
                                ]
                                Html.li [
                                    prop.className "paragraph paragraph_black"
                                    prop.text "AWS, Google Cloud, Azure"
                                ]
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "help__item help__item__box"
                        prop.children [
                            Html.h2 [
                                prop.className "title-secondary title-secondary_white"
                                prop.text (Localization.t "help.title")
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "skills"
                prop.children [
                    Html.div [
                        prop.className "skills__row1"
                        prop.children [

                            Html.img [
                                prop.src "./logos/fsharp.png"
                                prop.className "skills__bigLogo"
                            ]
                            Html.img [
                                prop.src "./logos/dotnet.png"
                                prop.className "skills__bigLogo"
                            ]
                            Html.div [
                                prop.className "skills__title"
                                prop.children [
                                Html.h3 [
                                    prop.className "title-secondary title-secondary_white"
                                    prop.text (Localization.t "technologies.title")
                                ]
                                Html.div [prop.className "skills__line"]
                                Html.p [
                                    prop.className "title-forth paragraph_white"
                                    prop.text (Localization.t "technologies.subtitle")
                                ]
                                ]
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "skills__row2"
                        prop.children [
                            Html.img [
                                prop.src "./logos/react2.png"
                                prop.className "skills__item"
                            ]
                            Html.img [
                                prop.src "./logos/flutter2.png"
                                prop.className "skills__item"
                            ]
                            Html.img [
                                prop.src "./logos/firebase2.png"
                                prop.className "skills__item"
                            ]
                            Html.img [
                                prop.src "./logos/aws.png"
                                prop.className "skills__item"
                            ]
                            Html.img [
                                prop.src "./logos/googlecloud2.png"
                                prop.className "skills__item"
                            ]
                            Html.img [
                                prop.src "./logos/azure2.png"
                                prop.className "skills__item"
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.id "clients"
                prop.className "portfolio"
                prop.children [
                    Html.div [
                        prop.className "portfolio__item"
                        prop.children [
                            Html.h3 [
                                prop.className "title-secondary title-secondary_white"
                                prop.text (Localization.t "portfolio.title")
                            ]
                            Html.div [prop.className "item-1__line"]
                            Html.p [
                                prop.className "title-forth paragraph_white"
                                prop.text (Localization.t "portfolio.subtitle")
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "portfolio__box"
                        prop.children [
                            for project in Database.projects |> List.sortBy(fun x -> x.Id) do
                                Html.a [
                                    prop.href project.Href
                                    prop.onClick(fun _ -> Browser.Dom.window.scrollTo(0,0))
                                    prop.className "portfolio__box__company"
                                    prop.children [
                                        Html.div [
                                            prop.className "portfolio__box__logo"
                                            prop.children [
                                                Html.img [
                                                    if project.Name = "Endurance" then
                                                        prop.className "portfolio__box__endurance"
                                                    prop.src project.LogoSrc
                                                ]
                                            ]
                                        ]
                                        Html.p [
                                            prop.className "paragraph paragraph_black"
                                            prop.text project.Applications
                                        ]
                                    ]
                                ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "clients"
                prop.children [
                    Html.div [
                        prop.className "clients__box"
                        prop.children [
                            Html.h3 [
                                prop.className "title-secondary title-secondary_black"
                                prop.text (Localization.t "testimonials")
                            ]
                        ]
                    ]
                    Html.div [
                        prop.className "clients__item"
                        prop.children [
                            Html.div [
                                prop.className "clients__item__box"
                                prop.children [
                                    Slick.slider [
                                        slider.dots true
                                        slider.centerMode false
                                        slider.arrows false
                                        slider.children [
                                            for client in Database.testimonials do
                                                Html.div [
                                                    prop.className "clients__slider"
                                                    prop.children [
                                                        Html.div [
                                                            prop.style[style.position.relative]
                                                            prop.children [
                                                                Html.div [
                                                                    prop.className "clients__slider__background"
                                                                ]
                                                                Html.img [
                                                                    prop.src client.Image
                                                                    prop.className "clients__slider__icon"
                                                                ]
                                                            ]
                                                        ]
                                                        Html.div [
                                                            prop.className "paragraph paragraph_white"
                                                            prop.text client.Text
                                                        ]
                                                        Html.span [
                                                            prop.children [
                                                                Html.p [
                                                                    prop.className "paragraph paragraph_white"
                                                                    prop.text client.Name
                                                                ]
                                                                Html.p [
                                                                    prop.className "paragraph paragraph_white paragraph_withOpacity"
                                                                    prop.text client.Company
                                                                ]
                                                            ]
                                                        ]

                                                    ]
                                                ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Footer.view
        ]
    ]