module Router

open Elmish.UrlParser
open Elmish.Navigation
open Shared

type Route =
    | Home
    | Pixie
    | Bargarn
    | Touchpoint
    | Shelfinity
    | SBH
    | Bidflow
    | Endurance
    | Direktpress

let idx state = custom "idx" (id >> Ok) state

let routeParser : Parser<Route->Route, Route> =
    oneOf
        [
          map Route.Home (s "home")
          map Route.Pixie (s "pixie")
          map Route.Bargarn (s "bargarn")
          map Route.Touchpoint (s "touchpoint")
          map Route.Shelfinity (s "shelfinity")
          map Route.SBH (s "sbh")
          map Route.Bidflow (s "bidflow")
          map Route.Endurance (s "endurance")
          map Route.Direktpress (s "direktpress")
        ]

let toPath =
    function
    | Route.Home -> "/#/home"
    | Route.Pixie -> "/#/pixie"
    | Route.Bargarn -> "/#/bargarn"
    | Route.Touchpoint -> "/#/touchpoint"
    | Route.Shelfinity -> "/#/shelfinity"
    | Route.SBH -> "/#/sbh"
    | Route.Bidflow -> "/#/bidflow"
    | Route.Endurance -> "/#/endurance"
    | Route.Direktpress -> "/#/direktpress"

let navigateTo page = page |> toPath |> Navigation.newUrl

let modifyUrl page = page |> toPath |> Navigation.modifyUrl
