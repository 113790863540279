module Index

open Elmish
open Fable.Remoting.Client
open Shared
open Slider
open Router

type Page =
    | Home
    | Pixie
    | Bargarn
    | Touchpoint
    | Shelfinity
    | SBH
    | Bidflow
    | Endurance
    | Direktpress

type State =
    { CurrentPage: Page }

type Msg =
    | Logout

let urlUpdate (nextPage : Route option) (state:State) =
    match nextPage with
    | Some Route.Home -> { state with CurrentPage = Home }, Cmd.none
    | Some Route.Pixie -> { state with CurrentPage = Pixie }, Cmd.none
    | Some Route.Bargarn -> { state with CurrentPage = Bargarn }, Cmd.none
    | Some Route.Touchpoint -> { state with CurrentPage = Touchpoint }, Cmd.none
    | Some Route.Shelfinity -> { state with CurrentPage = Shelfinity }, Cmd.none
    | Some Route.SBH -> { state with CurrentPage = SBH }, Cmd.none
    | Some Route.Bidflow -> { state with CurrentPage = Bidflow }, Cmd.none
    | Some Route.Endurance -> { state with CurrentPage = Endurance }, Cmd.none
    | Some Route.Direktpress -> { state with CurrentPage = Direktpress }, Cmd.none
    | None -> { state with CurrentPage = Home }, Cmd.none

let init page: State * Cmd<Msg> =
    let model =
        { CurrentPage = Home }
    urlUpdate page model

let update (msg: Msg) (state: State): State * Cmd<Msg> =
    match msg with
    | Logout -> state, Cmd.none

open Feliz
open Fable

let view (state:State) (dispatch: Msg -> unit)  =
    match state.CurrentPage with
    | Home -> Home.Render()
    | Pixie -> Components.Project.View 5
    | Bargarn -> Components.Project.View 6
    | Touchpoint -> Components.Project.View 7
    | Shelfinity -> Components.Project.View 8
    | SBH -> Components.Project.View 1
    | Bidflow -> Components.Project.View 2
    | Endurance -> Components.Project.View 3
    | Direktpress -> Components.Project.View 4