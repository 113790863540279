module Localization

open Fable.React
open Fable.Core.JsInterop
open Fable.Core

let i18n: obj = importDefault "i18next"
let resources: obj = importAll "./locales/index.js"



Browser.Dom.console.debug ("lng - ",LocalStorage.currentLanguage ())
Browser.Dom.console.debug (i18n?t("localization:ReceivedAt"))
let initParams = {|
    resources = resources
    lng = LocalStorage.currentLanguage ()
#if DEBUG
    debug = true
#endif
|}
i18n?init(initParams)

let t (key:string): string = i18n?t("localization:"+key)
let tWithArgs key args : string = i18n?t("localization:"+key, args)